<script setup lang="ts">
import { useFormattedHead } from "~/composables/useFormattedHead";

const { client } = usePrismic();
const { locale, localeProperties } = useI18n();
const { alternateLanguages } = storeToRefs(usePageStore());

const { data: document, error } = await useAsyncData(
  `home_page-${locale.value}`,
  async () => {
    const document = await client.getSingle("home_page", {
      lang: localeProperties.value.language,
    });
    return document;
  },
);

if (error.value) {
  console.log("HERE", error.value);
  throw createError({ statusCode: 404, message: "Page not found" });
}

useHead(
  useFormattedHead({
    title:
      document.value?.data.meta_title ||
      document.value?.data.slices
        .find((slice) => slice.slice_type === "logo_highlight")
        ?.primary.title.map((d) => d.text)
        .join(" ") ||
      "",
    description:
      document.value?.data.meta_description ||
      document.value?.data.slices
        .find((slice) => slice.slice_type === "logo_highlight")
        ?.primary.description.map((d) => d.text)
        .join(" ") ||
      "",
  }),
);

definePageMeta({
  layout: "home",
});

onNuxtReady(() => {
  alternateLanguages.value = document.value?.alternate_languages || [];
});
</script>
<template>
  <PageHome v-if="document" :document="document" />
</template>
